.service-cardIcon {
  width: 93px;
}

.service-card {
    box-shadow: 10px 10px 15px rgba(1, 103, 219, 0.3);
    background-color: #252e44;
    width: 20rem;
    height: 20rem;
    border-radius: 12px;
    overflow: hidden;
}

.card-p {
  text-overflow: ellipsis;
}

.service-cardRow {
  max-width: 1200PX;
  margin: 0 0 10vh;
}

.card-title, .card-text {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
.information-title {
  font-size: 14px;
}
.information-p {
  font-size: 12px;
}
}

@media (min-width: 769px) and (max-width: 1024px) {
  .information-title {
    font-size: 16px;
  }
  .information-p {
    font-size: 12px;
  }
}

@media (min-width: 1025px) {
  .information-title {
    font-size: 18px;
  }
  .information-p {
    font-size: 13px;
  }
}