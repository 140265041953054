/* AboutHero */
.about-main-body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 55px;
}

.info-sec {
background-color: #fff;
display: flex;
justify-content: center;
align-items: center;
}

.info-sec p {
text-align: center;
}

.ceo-section {
background-color: #252E44;
}

.team-card {
width: 18rem;
height: auto;
display: flex;
align-items: center;
background-color: #2F3953;
/* box-shadow: 10px 10px 15px rgba(1, 103, 219, 0.3); */
border: none;
}

.team-img {
width: 11rem;
height: 11rem;
object-position: top;
object-fit: cover;
border-radius: 50%;
overflow: hidden;
border: solid 3.5px #007bff;
}

.ff-positions {
background-color: #252E44;
}

.title-p {
  font-size: 12px;
}

.about-row{
max-width: 1200PX;
margin: 0 5rem 0 5rem;
}

@media (max-width: 768px) {
.about-row {
  margin-left: 3rem;
  margin-right: 3rem;
}
.info-sec {
  height: auto !important;
  padding-top: 2em;
  padding-bottom: 1em;
}
.aboutTeam-p {
  margin: 0 3rem 0 3rem; 
}
.about-hero-body {
  width: 100%;
  justify-content: left;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  height: 60vh;
  margin-top: 55px;
  background-image: url("../../assets/Mobile/about.webp");
}
}

@media (min-width: 769px) and (max-width: 1024px) {
.about-row {
  margin-left: 2rem;
  margin-right: 2rem;
}
.info-sec {
  height: auto !important;
  padding-top: 2em;
  padding-bottom: 1em;
}
.aboutTeam-p {
  margin: 0 10rem 0 10rem;
}
.about-hero-body {
  width: 100%;
  justify-content: left;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  height: 60vh;
  margin-top: 55px;
  background-image: url("../../assets/Hero/about.webp");
}
}

@media (min-width: 1025px) {
.info-sec{
  height: 10em !important;
}
.aboutTeam-p {
  margin: 0 10rem 0 10rem; 
  font-size: 15px;
}
.about-hero-body {
  width: 100%;
  justify-content: left;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  height: 60vh;
  margin-top: 55px;
  background-image: url("../../assets/Hero/about.webp");
}
}
