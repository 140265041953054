.main {
    bottom: 20px;  /* Position from the bottom */
    right: 20px; 
    position: fixed;
    background-color: #252E44;
    width: 40%;
    height: auto;
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  p {
    font-size: 13px;
    text-align: justify;
  }

  .accept-btn {
    background-color: #EFF7FF;
    color: #0167DB;
    border: solid 0.5px #0167DB;
    padding: 3px;
    z-index: 1000;
    font-size: 12px;
    width: 10rem;
  }
  .reject-btn {
    background-color: transparent;
    color: #0167DB;
    border: solid 0.5px #0167DB;
    padding: 3px;
    z-index: 1000;
    font-size: 12px;
    width: 10rem;
}


@media (max-width: 768px) {
    .main{
        bottom: 50% !important;
        right: 50% !important; 
        transform: translate(50%, 50%) ; 
        width: 95%;  
        max-width: 400px; 
    }
    .accept-btn {
        margin-right: 0px !important;
        width: 90%;
    }
    .reject-btn {
        margin-top: 10px;
        width: 90%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .main{
    width: 95%;  
    max-width: 400px; 
}
}

@media (min-width: 1025px) {

}
