.main-job-container {
    display: flex;
    flex-direction: column;   /* Ensures content stacks vertically */
    justify-content: flex-start; /* Align items at the start vertically */
    align-items: flex-start;  /* Align items at the start horizontally */
}

.category {
    display: inline-block; /* Ensures the background width matches the text content */
    background-color: #92C9FF; /* Replace with your desired background color */
    padding: 2px 8px; /* Adds space inside the background */
    border-radius: 4px; /* Optional: rounds the corners */
    margin-right: 5px;
}

.apply-btn {
    background-color: #0167DB;
    color: #fff;
    width: 12rem;
    height: 35px;
}

.apply-btn:hover {
    background-color: #92C9FF;
    color: #000;
    font-weight: bold;
}

.text-justify {
 text-align: justify;
}

@media (max-width: 768px) {
    li, h6 {
        font-size: 12px;
    }
    h4 {
        font-size: 18px;
    }
    h2 {
        font-size: 22px;
    }
    .category {
        font-size: 12px;
    }
    .apply-btn {
        font-size: 12px;
        width: 8rem;
        height: 25px;
    }
    .main-job-container {
        margin: 5rem 1rem;
    }
    .jobDescription, .responsibilities, .requirements, .qualifications, .about-us {
        padding-right: 2rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .main-job-container {
        margin: 4rem;
    }
    .jobDescription, .responsibilities, .requirements, .qualifications, .about-us {
        padding-right: 2rem;
    }
}

@media (min-width: 1025px) {
    .main-job-container {
        margin: 6rem 4rem;
    }
    .jobDescription, .responsibilities, .requirements, .qualifications, .about-us {
        padding-right: 18rem;
    }
}