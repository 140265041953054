.newsletter {
    height: 13rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.newsletter-text {
    color: #252E44;
    font-weight: 300;
}
.newsletter-button {
    margin-left: 13px;
    height: 2.75em;
    width: 10em;
    color: #EFF7FF;
    background-color: #252E44;
    font-weight: bold;
    font-size: 20px;
}

/* Style for the SweetAlert input placeholder */
.swal2-input::placeholder {
    color: black !important; /* Makes the placeholder text black */
  }

.swal2-input {
    border: 1px black solid;
}

@media (max-width: 768px) {
    .nl-lg {
        display: none !important;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .nl-sm {
        display: none !important;
    }
  }

@media (min-width: 1025px) {
    .nl-sm {
        display: none !important;
    }
  }