.overlay-text {
    position: absolute;
    top: 15%; /* Adjust to place the text higher */
    left: 5%;
    width: 100%;
    text-align: justify;
    color: black; /* Make sure text is visible on the image */
    z-index: 2; /* Position it above the image */
}

.overlay-text h1 {
    margin: 0;
    font-weight: 700;
    font-size: 6rem; /* Adjust as needed */
    line-height: 1; /* Adjust spacing between lines */
}

.home-btn {
    color: #000;
    padding: 5px;
    width: 10rem;
    margin-top: 2rem;
    background-color: #e6f0ff;
    border-left: .5px solid #007bff;
    border-bottom: .5px solid #007bff;
    box-shadow: -2px 2px 5px #007bff;
}

@media (max-width: 530px) {
    .error-lg {
        display: none !important;
    }
    .error-md {
        display: none !important;
    }
    .error-sm {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
    }
    .error-image {
        max-width: 100%;
        height: 42.3rem;
        display: block;
    } 
    .overlay-text {
        top: 5rem;
    }
    .overlay-text h1 {
        font-size: 3rem;
    }
    .home-btn {
        width: 10rem;
        height: 2rem;
        font-size: 1rem;
        margin-top: 1rem;
    }
}

@media (min-width: 531px) and (max-width: 1079px) {
    .error-lg {
        display: none !important;
    }
    .error-sm {
        display: none !important;
    }
    .error-md {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
    }
    .error-image {
        max-width: 100%;
        height: 42.3rem;
        display: block;
    }
    .overlay-text {
        top: 5rem;
    }
    .overlay-text h1 {
        font-size: 4rem;
    }
    .home-btn {
        width: 10rem;
        height: 2rem;
        font-size: 1rem;
        margin-top: 3rem;
    }
}
    
@media (min-width: 1080px) and (max-width: 6000px){
    .error-sm {
        display: none !important;
    }
    .error-md {
        display: none !important;
    }
    .error-lg {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
    }
    .error-image {
        width: 100%;
        height: auto !important;
        justify-content: left;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
    }    
}

@media (min-width: 2400px){
    .overlay-text {
        top: 10rem;
    }
    .home-btn {
        width: 12rem;
        height: 3rem;
        font-size: 1.2rem;
        margin-top: 3rem;
    }
}

@media (min-width: 3000px) {
    .overlay-text {
        top: 15rem;
    }
    .overlay-text h1 {
        font-size: 9rem;
    }
    .home-btn {
        width: 15rem;
        height: 5rem;
        font-size: 1.6rem;
        margin-top: 3rem;
    }
}

@media (min-width: 4117px) {
    .overlay-text {
        top: 20rem;
    }
    .overlay-text h1 {
        font-size: 12rem;
    }
    .home-btn {
        width: 20rem;
        height: 6rem;
        font-size: 2rem;
        margin-top: 5rem;
    }
}