.partnered-imgbg {
  background: linear-gradient(135deg, #92C9FF, #0167DB 80%); /* Blue gradient */
  color: #fff; /* White icon color */
  border-radius: 50%; /* Make the background circular */
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partneredCards {
  border: .5px solid #B6B2B2;
  width: 25rem;
  height: 10rem;
  border-radius: 12px;
  overflow-y: scroll;
  overflow: hidden;
}

.partnered-img {
  object-fit: cover;
  object-position: center;
}

.learnmore-btn {
  background-color: #0167DB;
  color: #fff;
  font-size: 10px;
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  bottom: 1.5rem; 
  left: 6rem;
}

.learnmore-btn:hover {
  background-color: #92C9FF;
  color: #000;
  font-weight: bold;
}

.partnered-para {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.part-sections {
  display: flex;
  justify-content: start;
  align-items: center;
  color: #0167DB;
}

.partnered-popup {
  background-color: #fff;
  color: #000;
  width: 40rem;
}

.partnered-swal-close-button {
  font-size: 1.5rem;
  color: #000;
  border: none;
}


@media (max-width: 768px) {
  .partnered-img {
    display: flex;
    width: 2.5rem;
  }
  .partnered-img{
    width: 38px;
    height: 38px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .partnered-img {
    display: flex;
    width: 3rem;
  }
}

@media (min-width: 1025px) {
  .partnered-img {
    display: flex;
    width: 3rem;
  }
}

@media (min-width: 1800px) and (max-width: 2399px){
  .partnered-img {
    height: 4vh !important;
  }
}

@media (min-width: 2400px){
  .partnered-img {
    height: 3.5vh !important;
  }
}

@media (min-width: 3000px) {
  .partnered-img {
    height: 2vh !important;
  }
}

@media (min-width: 4117px) {
  .partnered-img {
    height: 1.5vh !important;
  }
}


