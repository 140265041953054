.partners-body {
    position: relative; /* This is necessary for the child .overlay-title to position itself absolutely */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .mainPartner {
    max-width: 100%;
    display: block;
  }
  
  .overlay-title {
    position: absolute; /* Position it over the image */
    top: 35%; /* Adjusts position closer to the top of the image */
    left: 7%; /* Adjusts horizontal position */
    text-align: justify;
    color: black;
    z-index: 1; /* Ensures it's above the image */
  }
  
  .overlay-title h1 {
    margin: 0;
  }
  

@media (max-width: 599px) {
  .partnership-lg {
    display: none;
  }
  .partners-body-md {
    display: none;
  }
  .partnership-sm {
    width: 100%;
    height: 840px;
    justify-content: center;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    background-image: url("../../../assets/valuedpartnersmobile.webp");
  }
}

@media (min-width: 600px) and (max-width: 700px) {
  .partners-body-sm {
    display: none;
  }
  .partnership-lg {
    display: none;
  }
  
  .partnership-md {
    width: 100%;
    justify-content: center;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    height: 50vh;
    background-image: url("../../../assets/valuedpartnerstab.webp");
  }
}

@media (min-width: 701px) and (max-width: 1030px) {
  .partners-body-sm {
    display: none;
  }
  .partnership-lg {
    display: none;
  }
  
  .partnership-md {
    width: 100%;
    justify-content: center;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    height: 60vh;
    background-image: url("../../../assets/valuedpartnerstab.webp");
  }
}

@media (min-width: 1031px) {
  .partners-body-sm {
    display: none;
  }
  .partners-body-md {
    display: none;
  }
  .partnership-lg {
    width: 100%;
    justify-content: center;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    height: 60vh;
    background-image: url("../../../assets/valuedpartners.webp");
  }
}

@media (min-width: 2400px){
  .overlay-title {
    top: 40%;
    left: 15%;
  }
}

@media (min-width: 3000px) {
  .overlay-title {
    top: 45%;
    left: 20%;
  }
}

@media (min-width: 4117px) {
  .overlay-title {
    top: 47%;
    left: 23%;
  }
  .partnership-lg {
    margin-left: 40rem;
    width: 70%;
    height: 50vh;
  }
}