.App {
  text-align: center;
  width: inherit;
}

/* Information Section */
.info-section {
    background-color: #fff;
    display: flex; 
    align-items: center; 
    text-align: center; 
    color: #252e44;
    height: auto;
}

/* Hero */
.main-body {
    overflow: hidden;
    display: flex;
    align-items: center;
    background-image: url('./assets/Hero/main.webp');
    margin-top: 55px;
}

.hero-image {
    max-width: 100%;
    height: auto;
    display: block;
}

.connect-button {
    z-index: 1; /* Ensure it is above the image */
    height: 2rem;
    width: 8rem;
    background-color: #EFF7FF;
    color: #0167DB;
    border: solid 1px #0167DB;
}

@media (max-width: 768px) {
    .hero-lg{
        display: none;
    }
    .hero-md{
        display: none;
    }
    .main-body {
        width: 100%;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
        height: 40vh; 
        margin-top: 55px;
        background-image: url("./assets/Mobile/main.webp");
    }
    .hero-logo{
        width: 16em;
    }
    .connect-button {
        font-size: 0.5em; /* Slightly smaller font size */
        width: 60%;
    }
    .info-section {
        max-width: 100%;
        justify-content: center; 
        padding-top: 1em;
        padding-bottom: 1em;
    }
    .info-p {
        padding-top: 1em;
        margin: 0 3rem 0 3rem; 
    }
    .tagline {
        font-size: .75em;
    }
    
      
}

@media (min-width: 769px) and (max-width: 1024px) {
    .hero-lg{
        display: none;
    }
    .hero-sm{
        display: none;
    }
    .hero-sub{
        padding: 1em;
    }
    .tagline{
        padding: 1em;
    }
    .info-section {
        max-width: 100%;
        height: auto;
        justify-content: center; 
        padding: 0 1rem; 
    }
    .info-p {
        margin: 0 5rem 0 5rem;
    }
    .connect-button {
        font-size: 0.8em; /* Slightly smaller font size */
        width: 60%;
    }
    .main-body {
        width: 100%;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
        height: 45vh; 
        margin-top: 55px;
    }
    .hero-logo{
        width: 18em;
    }
    .tagline {
        font-size: 1.5em; 
    }
}

@media (min-width: 1025px) {
    .hero-sm{
        display: none;
    }
    .hero-md{
        display: none;
    }
    .info-section {
        max-width: 100%;
        height: auto;
        justify-content: center; 
        padding: 0 1em; 
      }
    .info-p {
        margin: 0 10rem 0 10rem; 
    }
    .main-body {
        width: 100%;
        justify-content: left;
        overflow: hidden;
        background-image: url('./assets//Hero//main.webp');
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
        height: 60vh;
    }
    .hero-margin {
        margin-left: 6em;
    }
    .hero-sub {
        width: 120em;
    }   
}

@media (min-width: 2400px){
    .hero-margin {
        margin-left: 10rem;
    }
    .hero-logo {
        width: 40em;
    }
    .tagline {
        font-size: 1.5em;
    }
}

@media (min-width: 3000px) {
    .hero-margin {
        margin-left: 20rem;
    }
    .hero-logo {
        width: 55em;
    }
    .tagline {
        font-size: 2em;
    }
}

@media (min-width: 4117px) {
    .hero-margin {
        margin-left: 25rem;
    }
    .hero-logo {
        width: 60em;
    }
    .tagline {
        font-size: 2.5em;
    }
}