/* ServicesHero */
.partners-main-body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-top: 55px;
}

.hex-bg {
  position: relative;
  width: 100%;
  justify-content: left;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  height: auto;
  margin-top: 55px;
  background-image: url("../../assets/hexagon.webp");
}

@media (max-width: 768px) {
  .partners-main-body {
    width: 100%;
    justify-content: left;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    height: 60vh;
    margin-top: 55px;
    background-image: url("../../assets/Mobile/partners.webp");
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .partners-main-body {
    width: 100%;
    justify-content: left;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    height: 60vh;
    margin-top: 55px;
    background-image: url("../../assets/Hero/partners.webp");
  }
}

@media (min-width: 1025px) {
  .partners-main-body {
    width: 100%;
    justify-content: left;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    height: 60vh;
    margin-top: 55px;
    background-image: url("../../assets/Hero/partners.webp");
  }
}

