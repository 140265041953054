.main-title {
    margin-top: 100px;
}

.privacy-p {
    color: black;
    font-size: 14px;
    text-align: justify;
    margin-bottom: 0;
}

.p-title {
    font-weight: bold;
    color: #0167db;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.p-subtitle{
    font-weight: bold;
    color: #0167DB;
}

@media (max-width: 768px) {
    .whole-body {
        margin: 3rem 1rem 0 1rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .whole-body {
        margin: 3rem 3rem 0 3rem;
    }
}

@media (min-width: 1025px) {
    .whole-body {
        margin: 3rem 10rem 0 10rem;
    }
}