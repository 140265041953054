.how {
  text-align: center;
}

/* FuionNode Fusion Center */
.fusion-center {
  max-width: 100%;
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  padding: 0 1rem;
}

.fusion-container {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.fusion-container h1 {
  margin: 5vh 0 5vh;
}

.how-image {
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

@media (max-width: 768px) {
.how-title {
  font-size: 20px;
}
.ai-p {
  margin: 0 1rem 0 1rem;
}
.how-body {
  width: 100%;
  justify-content: left;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  height: 60vh;
  margin-top: 55px;
  background-image: url("../../assets/Mobile/how.webp");
}
}

@media (min-width: 769px) and (max-width: 1024px) {
  .ai-p {
    margin: 0 5rem 0 5rem;
}
.how-body {
  width: 100%;
  justify-content: left;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  height: 60vh;
  margin-top: 55px;
  background-image: url("../../assets/Hero/how.webp");
}
}

@media (min-width: 1025px) {
  .ai-p {
    margin: 0 7rem 0 7rem;
  }
  .how-body {
    width: 100%;
    justify-content: left;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    height: 60vh;
    margin-top: 55px;
    background-image: url("../../assets/Hero/how.webp");
  }
}
