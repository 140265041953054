.about-form {
  background-color: #252E44;
}

.about-section {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.about-p {
  align-items: center;
}

.second-part {
  display: flex;
  flex-direction: row;
}

.input-box {
  display: flex;
  flex-direction: column;
}

.input-box input, textarea{
  background-color: #252E44;
  border-color: #fff;
  padding: 1rem;
  color: #fff;
}

.input-box textarea {
  height: 8rem;
}

.input-box button {
  height: 2.5rem;
  background-color: #EFF7FF;
  color: #0167DB;
  border-color: #0167DB;
  margin: 0 0.5rem 3rem;
}

.input-box button:hover {
  background-color: #d0e4ff;
}

.input-box input:-webkit-autofill {
  background-color: #252E44 !important; /* Change background color */
  color: #fff;
}

.input-box input:-webkit-autofill:hover,
.input-box input:-webkit-autofill:focus {
  background-color: #252E44 !important; /* Change background color on hover/focus */
  color: #fff !important;
}


/* POPUP */
/* Popup.css */
.swal2-confirm {
  background-color: #e6f0ff; /* Light background color for the Submit button */
  color: #007bff; /* Text color for the Submit button */
  border: 1px solid #007bff; /* Blue border for the Submit button */
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
  width: 8rem;
  border-radius: 0;
}

.swal2-cancel {
  background-color: transparent; /* Transparent background for Cancel button */
  color: #007bff; /* Text color for the Cancel button */
  border: 1px solid #007bff; /* Blue border for the Cancel button */
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
  width: 8rem;
  border-radius: 0;
}

.swal2-confirm:hover, .swal2-cancel:hover {
  background-color: #d0e4ff; /* Hover effect */
}

.swal2-popup {
  background-color: #92C9FF; /* Change background color */
  border-radius: 10px; /* Add rounded corners */
  padding: 20px; /* Add padding */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Add shadow */
  color: #252E44;
}

.swal2-icon.swal2-warning {
  color: #252E44; /* Change the color of the warning icon */
  border-color: #252E44; /* Change the border color of the warning icon */
}

.swal2-icon.swal2-success {
  border-color: #252E44; /* Custom outline color for success icon */
}

.swal2-icon.swal2-success .swal2-success-ring {
  border-color: #252E44; /* Customize only the ring outline */
}

/* Make the checkmark lines inside the success icon dark */
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #252E44; /* Color for the checkmark lines */
}

.swal2-icon.swal2-success .swal2-success-fix,
.swal2-icon.swal2-success .swal2-success-circular-line-left,
.swal2-icon.swal2-success .swal2-success-circular-line-right {
  background-color: transparent; /* Keep the background transparent */
}


.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #252E44;  
} 

.swal2-icon.swal2-success,
.swal2-icon.swal2-error {
  border-color: #252E44; /* Change border color for success and error icons */
}

@media (max-width: 768px) {
  .img-box{
    display: none;
  }
  .input-box input, textarea{
    height: 3rem;
    margin: 0.5rem ;
  }
  .about-p {
    margin: 0 3rem 0 3rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .img-box{
    display: none;
  }
  .input-box input, textarea{
    margin: 1em;
    height: 3rem;
  }
  .about-p {
    margin: 0 5rem 0 5rem;
  }
}

@media (min-width: 1025px) {

  .input-box input, textarea{
    width: 25rem;
    height: 3rem;
    margin: 0.5rem ;
  }
  .img-box {
    display: flex;
    justify-content: right;
    align-items:flex-start;
  }
  .input-box button {
    width: 10rem;
  }
  .about-p {
    margin: 0 10rem 0 10rem;
  }
  
}


