.hero-apply {
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-top: 55px;
}

.cards-container {
  position: relative;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
  height: auto;
  background-image: url("../../assets/hexagon.webp");
}

.job-row {
    max-width: 1200PX;
    margin: auto;
    margin: 3rem 0 3rem;
}

.job-p {
    line-height: 1.2;
}

.job-cards {
  background-color: #fff;
  box-shadow: 10px 10px 15px rgba(1, 103, 219, 0.3);
  border-radius: 12px;
  overflow: hidden;
}

.bottom-part {
    display: flex;
    justify-content: center;
    align-items: center;
}

.view-btn {
    width: 10rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border: none;
}

.view-btn:hover {
    background-color: #92C9FF;
    color: #000;
    font-weight: bold;
}

@media (max-width: 768px) {
    .hero-apply {
        width: 100%;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
        height: 40vh; 
        margin-top: 55px;
        background-image: url("../../assets/Mobile/apply.webp");
    }
    .job-card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column !important;
        height: auto;
        margin: 0 2rem 0 2rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .hero-apply {
        width: 100%;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
        height: 45vh; 
        margin-top: 55px;
        background-image: url('../../assets/Hero/apply.webp');
    }
}

@media (min-width: 1025px) {
    .hero-apply {
        width: 100%;
        justify-content: left;
        overflow: hidden;
        background-image: url('../../assets/Hero/apply.webp');
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
        height: 60vh;
    }
    .job-card-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 3rem;
    }
}